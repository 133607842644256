import { OCPP_API } from "./common/http-common";

export function getSelectedChargerEvent(chargerId, from) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.get(
    `/${tenant}/chargepoints/${chargerId}/events?from=${from}`
  );
}

export function getChargerEventByName(chargerId, from, name) {
    const tenant = process.env.VUE_APP_TENANT;
    return OCPP_API.get(
        `/${tenant}/chargepoints/${chargerId}/events?from=${from}&pduName=${name}`
    );
}
