import { OCPP_API } from "./common/http-common";

export function triggerChargerCommands(chargerId, action, connectorId) {
  const tenant = process.env.VUE_APP_TENANT;
  let message = {
    chargerId: chargerId,
    action: action,
  };

  if (connectorId) {
    message = Object.assign({ connectorId: connectorId }, message);
  }
  return OCPP_API.post(`/${tenant}/centralsystems`, message);
}
