export function randomString() {
  let text = "";
  const array = new Uint32Array(10);
  window.crypto.getRandomValues(array);
  for (let i = 0; i < array.length; i++) {
    text = array[i];
  }
  return dec2hex(text);
}

export function dec2hex(dec) {
  return (+dec).toString(16).toUpperCase();
}

export function copyToClipboard(string) {
  navigator.clipboard.writeText(string);
}
