import { OCPP_API } from "./common/http-common";

export function changeChargerAuth(chargerId, key) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.post(`/${tenant}/assets/chargers/${chargerId}/auth`, {
    authorizationKey: key,
  });
}

export function getChargerAuth(chargerId) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.get(`/${tenant}/assets/chargers/${chargerId}/authorization`);
}
