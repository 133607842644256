import { OCPP_API } from "./common/http-common";

export function getOtpForCharger(chargerId) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.get(
    `/${tenant}/assets/v1.0/tenants/${tenant}/customerchargers/chargerexternalid/${chargerId}/role/OWNER`
  );
}

export function createOtp(chargerId) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.put(
    `/${tenant}/assets/v1.0/tenants/${tenant}/customerchargers/otp`,
    {
      chargerId: chargerId,
    }
  );
}
